import React, { useContext } from 'react';
import { MediaContext } from '../../context/MediaContext';
import style from './AudioBookItem.module.css';

const AudioBookItem = ({ type, audioImg, audio }) => {
  const { currentMedia, playMedia, stopMedia } = useContext(MediaContext);

  const handlePlay = (mediaId) => {
    if (currentMedia !== mediaId) {
      playMedia(mediaId);
    }
  };

  // useEffect(() => {
  //   // Selecciona todos los elementos de audio una vez que el componente ha sido montado
  //   const audios = document.querySelectorAll('.audio-player');

  //   audios.forEach(audio => {
  //     audio.addEventListener('play', () => {
  //       // Pausa todos los demás audios y reinicia su tiempo
  //       audios.forEach(otherAudio => {
  //         if (otherAudio !== audio) {
  //           otherAudio.pause();
  //           otherAudio.currentTime = 0; // Reinicia el audio
  //         }
  //       });
  //     });
  //   });

  //   // Limpieza: elimina los event listeners cuando el componente se desmonta
  //   return () => {
  //     audios.forEach(audio => {
  //       audio.removeEventListener('play', () => {});
  //     });
  //   };
  // }, [type]); // Ejecutar solo una vez al montar el componente

  return (
   
      <div className={style.AudioItemContainer}>
      {type === 'audio' ? (
        <>
          <img src={audioImg} className={style.AudioItemImg} alt="Audio Thumbnail" />
          <audio controls className={`audio-player ${style.AudioItemAudio}`}
          onPlay={() => handlePlay(audio)}
          onPause={stopMedia}>
            <source src={audio} type="audio/mpeg" />
            Tu navegador no soporta el elemento de audio.
          </audio>
        </>
      ) : (
        <video
          controls
          poster={audioImg} 
          className={`audio-player ${style.VideoItem}`}
          onPlay={() => handlePlay(audio)}
            onPause={stopMedia}
        >
          <source src={audio} type="video/mp4" />
          Tu navegador no soporta el elemento de video.
        </video>
      )}
    </div>
  );
};

export default AudioBookItem;
