import React, { createContext, useState } from 'react';

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const [currentMedia, setCurrentMedia] = useState(null);

  const playMedia = (mediaId) => {
    setCurrentMedia(mediaId);
  };

  const stopMedia = () => {
    setCurrentMedia(null);
  };

  return (
    <MediaContext.Provider value={{ currentMedia, playMedia, stopMedia }}>
      {children}
    </MediaContext.Provider>
  );
};
