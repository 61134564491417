import React, { useRef, useEffect } from 'react'
import  style from "./Cover.module.css";
import video1 from '../../media/videos/Home Video Web.mp4';

const Cover = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);
  
  return (
    <div className={style.cover} id='home'>
        <video
        ref={videoRef}
        autoplay
        muted
        loop
        controls={true} // Si no quieres mostrar los controles
        className={style.aboutVideo} // Ajuste de tamaño opcional
      ><source src={video1} type="video/mp4" /></video>
    </div>
  )
}

export default Cover