import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import style from './Contact.module.css';
import img from '../../media/img/logo blanco sin fondo.png';
import { IconBrandLinkedin, IconBrandInstagram, IconBrandTiktok, IconBrandYoutube } from '@tabler/icons-react';

const Contact = ({ language, translations }) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Estado para el loading

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true); // Activar el estado de carga

    const templateParams = {
      name: name,
      email: email,
      message: message,
    };

    emailjs
      .send(
        'service_4lvskh8',
        'template_3czzdd8',
        templateParams,
        'GaDGaGCKZMT6HPeDE'
      )
      .then(() => {
        setFeedback(translations[language].contactoExito);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch(() => {
        setFeedback(translations[language].contactoError);
      })
      .finally(() => {
        setIsLoading(false); // Desactivar el estado de carga
        setTimeout(() => setFeedback(''), 5000);
      });
  };

  return (
    <div className={style.contactContainerAll}>
      <div className={style.divLeft}>
        <div className={style.divLogoContact}>
          <div><img src={img} alt="Etervoz Logo"/></div>
          <div> 
            <h1>Etervoz</h1>
            <h2>{translations[language].contactoLogo}</h2>
          </div>
        </div>
        <div className={style.divRedesContact}>
          <h2>{translations[language].contactoText}</h2>
          <div className={style.redesDiv}>
            <div><a href='https://www.linkedin.com/in/ezealvarez/' target='_blank'><IconBrandLinkedin className={style.redIcono}/></a></div>
            <div><a href='https://www.instagram.com/etervoz/' target='_blank'><IconBrandInstagram className={style.redIcono}/></a></div>
            <div><a href='https://www.tiktok.com/@etervoz' target='_blank'><IconBrandTiktok className={style.redIcono}/></a></div>
            <div><a href='https://www.youtube.com/@etervozproducciones3191' target='_blank'><IconBrandYoutube className={style.redIcono}/></a></div>
          </div>
        </div>
      </div>
      <div className={style.divRight}>
        <h2>{translations[language].contactoFormTitulo}</h2>
        <form onSubmit={sendEmail}>
          <input 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            type="text" 
            id="name" 
            name="name" 
            placeholder={translations[language].contactoFormNombre} 
            required 
          />
          <input 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            type="email" 
            id="email" 
            name="email" 
            placeholder={translations[language].contactoFormEmail} 
            required 
          />
          <textarea 
            id="message" 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            name="message" 
            rows="4" 
            placeholder={translations[language].contactoFormMensaje} 
            required 
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? translations[language].loadingText : translations[language].contactoFormBoton}
          </button>
        </form>

        {/* Mensaje de retroalimentación */}
        {feedback && <p className={style.feedbackMessage}>{feedback}</p>}
      </div>
    </div>
  );
};

export default Contact;
